<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['alwaysUsesSunscreen'])">
      <single-answer-question
        title="label.alwaysUseSunscreen"
        :value="alwaysUsesSunscreen"
        :options="$options.yesNoOptions"
        dysfunction="oxidative-stress"
        yes-no
        @input="onFieldChange('alwaysUsesSunscreen', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['currentlyTanned'])">
      <single-answer-question
        id="tanned-body"
        title="label.currentlyTanned"
        :value="currentlyTanned"
        :options="$options.yesNoOptions"
        dysfunction="oxidative-stress"
        yes-no
        @input="onFieldChange('currentlyTanned', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

export default {
  name: 'RelationshipWithSunTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion },
  mixins: [stepTemplateMixin],
  yesNoOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    alwaysUsesSunscreen: {
      type: String,
      default: ''
    },
    currentlyTanned: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped></style>
